import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/items')
const postAxios = data => axiosWarehouse.post('/items', data)
const oneAxios = id => axiosWarehouse.get(`/items/${id}`)
const putAxios = (id, data) => axiosWarehouse.post(`/items/${id}?_method=PUT`, data)
const deleteAxios = id => axiosWarehouse.delete(`/items/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
