export default [
  {
    path: '/administration/area/index',
    name: 'administration-areas',
    component: () => import('@/views/administration/area/index.vue'),
    meta: {
      pageTitle: 'Areas',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Areas',
          active: true,
        },
      ],
      resource: 'Area',
      action: 'read',
    },
  },
  {
    path: '/administration/area/add',
    name: 'administration-addArea',
    component: () => import('@/views/administration/area/add.vue'),
    meta: {
      pageTitle: 'Add Area',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Area',
          active: true,
        },
      ],
      resource: 'Area',
      action: 'add',
    },
  },
  {
    path: '/administration/area/update/:id',
    name: 'administration-updateArea',
    component: () => import('@/views/administration/area/update.vue'),
    meta: {
      pageTitle: 'Update Area',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Area',
          active: true,
        },
      ],
      resource: 'Area',
      action: 'update',
    },
  },
  {
    path: '/administration/bank/index',
    name: 'administration-banks',
    component: () => import('@/views/administration/bank/index.vue'),
    meta: {
      pageTitle: 'Banks',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Banks',
          active: true,
        },
      ],
      resource: 'Bank',
      action: 'read',
    },
  },
  {
    path: '/administration/bank/add',
    name: 'administration-addBank',
    component: () => import('@/views/administration/bank/add.vue'),
    meta: {
      pageTitle: 'Add Bank',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Bank',
          active: true,
        },
      ],
      resource: 'Bank',
      action: 'add',
    },
  },
  {
    path: '/administration/bank/update/:id',
    name: 'administration-updateBank',
    component: () => import('@/views/administration/bank/update.vue'),
    meta: {
      pageTitle: 'Update Bank',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Bank',
          active: true,
        },
      ],
      resource: 'Bank',
      action: 'update',
    },
  },
  {
    path: '/administration/branch/index',
    name: 'administration-branches',
    component: () => import('@/views/administration/branch/index.vue'),
    meta: {
      pageTitle: 'Branches',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Branches',
          active: true,
        },
      ],
      resource: 'Branch',
      action: 'read',
    },
  },
  {
    path: '/administration/branch/add',
    name: 'administration-addBranch',
    component: () => import('@/views/administration/branch/add.vue'),
    meta: {
      pageTitle: 'Add Branch',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Branch',
          active: true,
        },
      ],
      resource: 'Branch',
      action: 'add',
    },
  },
  {
    path: '/administration/branch/update/:id',
    name: 'administration-updateBranch',
    component: () => import('@/views/administration/branch/update.vue'),
    meta: {
      pageTitle: 'Update Branch',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Branch',
          active: true,
        },
      ],
      resource: 'Branch',
      action: 'update',
    },
  },
  {
    path: '/administration/bankBranch/index',
    name: 'administration-bankBranches',
    component: () => import('@/views/administration/bank-branch/index.vue'),
    meta: {
      pageTitle: 'Bank Branches',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Bank Branches',
          active: true,
        },
      ],
      resource: 'BankBranch',
      action: 'read',
    },
  },
  {
    path: '/administration/bankBranch/add',
    name: 'administration-addBankBranch',
    component: () => import('@/views/administration/bank-branch/add.vue'),
    meta: {
      pageTitle: 'Add bank Branch',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Bank Branch',
          active: true,
        },
      ],
      resource: 'BankBranch',
      action: 'add',
    },
  },
  {
    path: '/administration/bankBranch/update/:id',
    name: 'administration-updateBankBranch',
    component: () => import('@/views/administration/bank-branch/update.vue'),
    meta: {
      pageTitle: 'Update Bank Branch',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Bank Branch',
          active: true,
        },
      ],
      resource: 'BankBranch',
      action: 'update',
    },
  },
  {
    path: '/administration/calendar/index',
    name: 'administration-calendars',
    component: () => import('@/views/administration/calendar/index.vue'),
    meta: {
      pageTitle: 'Calendars',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Calendars',
          active: true,
        },
      ],
      resource: 'Calendar',
      action: 'read',
    },
  },
  {
    path: '/administration/calendar/add',
    name: 'administration-addCalendar',
    component: () => import('@/views/administration/calendar/add.vue'),
    meta: {
      pageTitle: 'Add Calendar',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Calendar',
          active: true,
        },
      ],
      resource: 'Calendar',
      action: 'add',
    },
  },
  {
    path: '/administration/calendar/update/:id',
    name: 'administration-updateCalendar',
    component: () => import('@/views/administration/calendar/update.vue'),
    meta: {
      pageTitle: 'Update Calendar',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Calendar',
          active: true,
        },
      ],
      resource: 'Calendar',
      action: 'update',
    },
  },
  {
    path: '/administration/city/index',
    name: 'administration-cities',
    component: () => import('@/views/administration/city/index.vue'),
    meta: {
      pageTitle: 'Cities',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Cities',
          active: true,
        },
      ],
      resource: 'City',
      action: 'read',
    },
  },
  {
    path: '/administration/city/add',
    name: 'administration-addCity',
    component: () => import('@/views/administration/city/add.vue'),
    meta: {
      pageTitle: 'Add City',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add City',
          active: true,
        },
      ],
      resource: 'City',
      action: 'add',
    },
  },
  {
    path: '/administration/city/update/:id',
    name: 'administration-updateCity',
    component: () => import('@/views/administration/city/update.vue'),
    meta: {
      pageTitle: 'Update City',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update City',
          active: true,
        },
      ],
      resource: 'City',
      action: 'update',
    },
  },
  {
    path: '/administration/contractType/index',
    name: 'administration-contractTypes',
    component: () => import('@/views/administration/contract-type/index.vue'),
    meta: {
      pageTitle: 'Contract Types',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Contract Types',
          active: true,
        },
      ],
      resource: 'ContractType',
      action: 'read',
    },
  },
  {
    path: '/administration/contractType/add',
    name: 'administration-addContractType',
    component: () => import('@/views/administration/contract-type/add.vue'),
    meta: {
      pageTitle: 'Add Contract Type',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Contract Type',
          active: true,
        },
      ],
      resource: 'ContractType',
      action: 'add',
    },
  },
  {
    path: '/administration/contractType/update/:id',
    name: 'administration-updateContractType',
    component: () => import('@/views/administration/contract-type/update.vue'),
    meta: {
      pageTitle: 'Update Contract Type',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Contract Type',
          active: true,
        },
      ],
      resource: 'ContractType',
      action: 'update',
    },
  },
  {
    path: '/administration/country/index',
    name: 'administration-countries',
    component: () => import('@/views/administration/country/index.vue'),
    meta: {
      pageTitle: 'Countries',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Countries',
          active: true,
        },
      ],
      resource: 'Country',
      action: 'read',
    },
  },
  {
    path: '/administration/country/add',
    name: 'administration-addCountry',
    component: () => import('@/views/administration/country/add.vue'),
    meta: {
      pageTitle: 'Add Country',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Country',
          active: true,
        },
      ],
      resource: 'Country',
      action: 'add',
    },
  },
  {
    path: '/administration/country/update/:id',
    name: 'administration-updateCountry',
    component: () => import('@/views/administration/country/update.vue'),
    meta: {
      pageTitle: 'Update Country',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Country',
          active: true,
        },
      ],
      resource: 'Country',
      action: 'update',
    },
  },
  {
    path: '/administration/document/index',
    name: 'administration-documents',
    component: () => import('@/views/administration/document/index.vue'),
    meta: {
      pageTitle: 'Documents',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Documents',
          active: true,
        },
      ],
      resource: 'Document',
      action: 'read',
    },
  },
  {
    path: '/administration/document/add',
    name: 'administration-addDocument',
    component: () => import('@/views/administration/document/add.vue'),
    meta: {
      pageTitle: 'Add Document',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Document',
          active: true,
        },
      ],
      resource: 'Document',
      action: 'add',
    },
  },
  {
    path: '/administration/document/update/:id',
    name: 'administration-updateDocument',
    component: () => import('@/views/administration/document/update.vue'),
    meta: {
      pageTitle: 'Update Document',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Document',
          active: true,
        },
      ],
      resource: 'Document',
      action: 'update',
    },
  },
  {
    path: '/administration/employee/index',
    name: 'administration-employees',
    component: () => import('@/views/administration/employee/index.vue'),
    meta: {
      pageTitle: 'Employees',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Employees',
          active: true,
        },
      ],
      resource: 'Employee',
      action: 'read',
    },
  },
  {
    path: '/administration/employee/add',
    name: 'administration-addEmployee',
    component: () => import('@/views/administration/employee/add.vue'),
    meta: {
      pageTitle: 'Add Employee',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Employee',
          active: true,
        },
      ],
      resource: 'Employee',
      action: 'add',
    },
  },
  {
    path: '/administration/employee/update/:id',
    name: 'administration-updateEmployee',
    component: () => import('@/views/administration/employee/update.vue'),
    meta: {
      pageTitle: 'Update Employee',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Employee',
          active: true,
        },
      ],
      resource: 'Employee',
      action: 'update',
    },
  },
  {
    path: '/administration/genderDegreer/index',
    name: 'administration-genderDegreers',
    component: () => import('@/views/administration/gender-degreer/index.vue'),
    meta: {
      pageTitle: 'Gender Degreers',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gender Degreers',
          active: true,
        },
      ],
      resource: 'GenderDegreer',
      action: 'read',
    },
  },
  {
    path: '/administration/genderDegreer/add',
    name: 'administration-addGenderDegreer',
    component: () => import('@/views/administration/gender-degreer/add.vue'),
    meta: {
      pageTitle: 'Add Gender Degreer',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Gender Degreer',
          active: true,
        },
      ],
      resource: 'GenderDegreer',
      action: 'add',
    },
  },
  {
    path: '/administration/genderDegreer/update/:id',
    name: 'administration-updateGenderDegreer',
    component: () => import('@/views/administration/gender-degreer/update.vue'),
    meta: {
      pageTitle: 'Update Gender Degreer',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Gender Degreer',
          active: true,
        },
      ],
      resource: 'GenderDegreer',
      action: 'update',
    },
  },
  {
    path: '/administration/gender/index',
    name: 'administration-genders',
    component: () => import('@/views/administration/gender/index.vue'),
    meta: {
      pageTitle: 'Genders',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Genders',
          active: true,
        },
      ],
      resource: 'Gender',
      action: 'read',
    },
  },
  {
    path: '/administration/gender/add',
    name: 'administration-addGender',
    component: () => import('@/views/administration/gender/add.vue'),
    meta: {
      pageTitle: 'Add Gender',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Gender',
          active: true,
        },
      ],
      resource: 'Gender',
      action: 'add',
    },
  },
  {
    path: '/administration/gender/update/:id',
    name: 'administration-updateGender',
    component: () => import('@/views/administration/gender/update.vue'),
    meta: {
      pageTitle: 'Update Gender',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Gender',
          active: true,
        },
      ],
      resource: 'Gender',
      action: 'update',
    },
  },
  {
    path: '/administration/group/index',
    name: 'administration-groups',
    component: () => import('@/views/administration/group/index.vue'),
    meta: {
      pageTitle: 'Groups',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Groups',
          active: true,
        },
      ],
      resource: 'Group',
      action: 'read',
    },
  },
  {
    path: '/administration/group/add',
    name: 'administration-addGroup',
    component: () => import('@/views/administration/group/add.vue'),
    meta: {
      pageTitle: 'Add Group',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Group',
          active: true,
        },
      ],
      resource: 'Group',
      action: 'add',
    },
  },
  {
    path: '/administration/group/update/:id',
    name: 'administration-updateGroup',
    component: () => import('@/views/administration/group/update.vue'),
    meta: {
      pageTitle: 'Update Group',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Group',
          active: true,
        },
      ],
      resource: 'Group',
      action: 'update',
    },
  },
  {
    path: '/administration/language/index',
    name: 'administration-languages',
    component: () => import('@/views/administration/language/index.vue'),
    meta: {
      pageTitle: 'Languages',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Languages',
          active: true,
        },
      ],
      resource: 'Language',
      action: 'read',
    },
  },
  {
    path: '/administration/language/add',
    name: 'administration-addLanguage',
    component: () => import('@/views/administration/language/add.vue'),
    meta: {
      pageTitle: 'Add Language',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Language',
          active: true,
        },
      ],
      resource: 'Language',
      action: 'add',
    },
  },
  {
    path: '/administration/language/update/:id',
    name: 'administration-updateLanguage',
    component: () => import('@/views/administration/language/update.vue'),
    meta: {
      pageTitle: 'Update Language',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Language',
          active: true,
        },
      ],
      resource: 'Language',
      action: 'update',
    },
  },
  {
    path: '/administration/nationality/index',
    name: 'administration-nationalities',
    component: () => import('@/views/administration/nationality/index.vue'),
    meta: {
      pageTitle: 'Nationalities',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Nationalities',
          active: true,
        },
      ],
      resource: 'Nationality',
      action: 'read',
    },
  },
  {
    path: '/administration/nationality/add',
    name: 'administration-addNationality',
    component: () => import('@/views/administration/nationality/add.vue'),
    meta: {
      pageTitle: 'Add Nationality',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Nationality',
          active: true,
        },
      ],
      resource: 'Nationality',
      action: 'add',
    },
  },
  {
    path: '/administration/nationality/update/:id',
    name: 'administration-updateNationality',
    component: () => import('@/views/administration/nationality/update.vue'),
    meta: {
      pageTitle: 'Update Nationality',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Nationality',
          active: true,
        },
      ],
      resource: 'Nationality',
      action: 'update',
    },
  },
  {
    path: '/administration/organization/index',
    name: 'administration-organizations',
    component: () => import('@/views/administration/organization/index.vue'),
    meta: {
      pageTitle: 'Organizations',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Organizations',
          active: true,
        },
      ],
      resource: 'Organization',
      action: 'read',
    },
  },
  {
    path: '/administration/organization/add',
    name: 'administration-addOrganization',
    component: () => import('@/views/administration/organization/add.vue'),
    meta: {
      pageTitle: 'Add Organization',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Organization',
          active: true,
        },
      ],
      resource: 'Organization',
      action: 'add',
    },
  },
  {
    path: '/administration/organization/update/:id',
    name: 'administration-updateOrganization',
    component: () => import('@/views/administration/organization/update.vue'),
    meta: {
      pageTitle: 'Update Organization',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Organization',
          active: true,
        },
      ],
      resource: 'Organization',
      action: 'update',
    },
  },
  {
    path: '/administration/socialStatus/index',
    name: 'administration-socialStatuses',
    component: () => import('@/views/administration/social-status/index.vue'),
    meta: {
      pageTitle: 'Social Statuses',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Social Statuses',
          active: true,
        },
      ],
      resource: 'SocialStatus',
      action: 'read',
    },
  },
  {
    path: '/administration/socialStatus/add',
    name: 'administration-addSocialStatus',
    component: () => import('@/views/administration/social-status/add.vue'),
    meta: {
      pageTitle: 'Add Social Status',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Social Status',
          active: true,
        },
      ],
      resource: 'SocialStatus',
      action: 'add',
    },
  },
  {
    path: '/administration/socialStatus/update/:id',
    name: 'administration-updateSocialStatus',
    component: () => import('@/views/administration/social-status/update.vue'),
    meta: {
      pageTitle: 'Update Social Status',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Social Status',
          active: true,
        },
      ],
      resource: 'SocialStatus',
      action: 'update',
    },
  },
  {
    path: '/administration/payment/index',
    name: 'administration-payments',
    component: () => import('@/views/administration/payment/index.vue'),
    meta: {
      pageTitle: 'Payments',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Payments',
          active: true,
        },
      ],
      resource: 'Payment',
      action: 'read',
    },
  },
  {
    path: '/administration/payment/add',
    name: 'administration-addPayment',
    component: () => import('@/views/administration/payment/add.vue'),
    meta: {
      pageTitle: 'Add Payment',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Payment',
          active: true,
        },
      ],
      resource: 'Payment',
      action: 'add',
    },
  },
  {
    path: '/administration/payment/update/:id',
    name: 'administration-updatePayment',
    component: () => import('@/views/administration/payment/update.vue'),
    meta: {
      pageTitle: 'Update Payment',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Payment',
          active: true,
        },
      ],
      resource: 'Payment',
      action: 'update',
    },
  },
  {
    path: '/administration/accountingOperation/index',
    name: 'administration-accountingOperations',
    component: () => import('@/views/administration/accounting-operation/index.vue'),
    meta: {
      pageTitle: 'Accounting Operations',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Accounting Operations',
          active: true,
        },
      ],
      resource: 'AccountingOperation',
      action: 'read',
    },
  },
  {
    path: '/administration/accountingOperation/add',
    name: 'administration-addAccountingOperation',
    component: () => import('@/views/administration/accounting-operation/add.vue'),
    meta: {
      pageTitle: 'Add Accounting Operation',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Accounting Operation',
          active: true,
        },
      ],
      resource: 'AccountingOperation',
      action: 'add',
    },
  },
  {
    path: '/administration/accountingOperation/update/:id',
    name: 'administration-updateAccountingOperation',
    component: () => import('@/views/administration/accounting-operation/update.vue'),
    meta: {
      pageTitle: 'Update Accounting Operation',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Accounting Operation',
          active: true,
        },
      ],
      resource: 'AccountingOperation',
      action: 'update',
    },
  },
  {
    path: '/administration/status/index',
    name: 'administration-statuses',
    component: () => import('@/views/administration/status/index.vue'),
    meta: {
      pageTitle: 'Statuses',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'statuses',
          active: true,
        },
      ],
      resource: 'Status',
      action: 'read',
    },
  },
  {
    path: '/administration/status/add',
    name: 'administration-addStatus',
    component: () => import('@/views/administration/status/add.vue'),
    meta: {
      pageTitle: 'Add Status',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Add Status',
          active: true,
        },
      ],
      resource: 'Status',
      action: 'add',
    },
  },
  {
    path: '/administration/status/update/:id',
    name: 'administration-updateStatus',
    component: () => import('@/views/administration/status/update.vue'),
    meta: {
      pageTitle: 'Update Status',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Update Status',
          active: true,
        },
      ],
      resource: 'Status',
      action: 'update',
    },
  },
]
