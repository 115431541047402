import Vue from 'vue'

// axios
import axios from 'axios'

// const token = '3|cKbkxUBT37VRcrMX6e6xnXh2tWOSzJjSjZUCjCs1'
const axiosWarehouse = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://erp.growsmart-ss.com/api',
  // baseURL: 'http://127.0.0.1:9900/api',
  timeout: 100000,
  headers: { 'X-Custom-Header': 'foobar' },
})
axiosWarehouse.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.enctype = 'multipart/form-data; boundary=something'
  // eslint-disable-next-line no-param-reassign
  // config.headers.Accept = 'application/json ,text/plain, */*'
  return config
})

Vue.prototype.$http = axiosWarehouse

export default axiosWarehouse
