export default [
  {
    path: '/purchase/request/index',
    name: 'purchase-request',
    component: () => import('@/views/purchase/request/index.vue'),
    meta: {
      pageTitle: 'Purchases Request',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Request',
          active: true,
        },
      ],
      resource: 'PurchasesRequest',
      action: 'read',
    },
  },
  {
    path: '/purchase/request/preview/:id',
    name: 'purchase-previewRequest',
    component: () => import('@/views/purchase/request/preview.vue'),
    meta: {
      resource: 'PurchasesRequest',
      action: 'read',
    },
  },
  {
    path: '/purchase/request/add',
    name: 'purchase-addPurchasesRequest',
    component: () => import('@/views/purchase/request/add.vue'),
    meta: {
      pageTitle: 'Add Purchases Request',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Add Purchases Request',
          active: true,
        },
      ],
      resource: 'PurchasesRequest',
      action: 'add',
    },
  },
  {
    path: '/purchase/request/update/:id',
    name: 'purchase-updatePurchasesRequest',
    component: () => import('@/views/purchase/request/update.vue'),
    meta: {
      pageTitle: 'Update Purchases Request',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Update Purchases Request',
          active: true,
        },
      ],
      resource: 'PurchasesRequest',
      action: 'update',
    },
  },

  {
    path: '/purchase/offer/index',
    name: 'purchase-offer',
    component: () => import('@/views/purchase/offer/index.vue'),
    meta: {
      pageTitle: 'Purchases Price Offer',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Price Offer',
          active: true,
        },
      ],
      resource: 'PurchasesPriceOffer',
      action: 'read',
    },
  },
  {
    path: '/purchase/offer/preview/:id',
    name: 'purchase-previewOffer',
    component: () => import('@/views/purchase/offer/preview.vue'),
    meta: {
      resource: 'PurchasesPriceOffer',
      action: 'read',
    },
  },
  {
    path: '/purchase/offer/add',
    name: 'purchase-addPriceOffer',
    component: () => import('@/views/purchase/offer/add.vue'),
    meta: {
      pageTitle: 'Add Purchases Price Offer',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Add Price Offer',
          active: true,
        },
      ],
      resource: 'PurchasesPriceOffer',
      action: 'add',
    },
  },
  {
    path: '/purchase/offer/update/:id',
    name: 'purchase-updatePriceOffer',
    component: () => import('@/views/purchase/offer/update.vue'),
    meta: {
      pageTitle: 'Update Purchases Price Offer',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Update Price Offer',
          active: true,
        },
      ],
      resource: 'PurchasesPriceOffer',
      action: 'update',
    },
  },

  {
    path: '/purchase/order/index',
    name: 'purchase-order',
    component: () => import('@/views/purchase/order/index.vue'),
    meta: {
      pageTitle: 'Order',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Order',
          active: true,
        },
      ],
      resource: 'PurchasesOrder',
      action: 'read',
    },
  },
  {
    path: '/purchase/order/preview/:id',
    name: 'purchase-previewOrder',
    component: () => import('@/views/purchase/order/preview.vue'),
    meta: {
      resource: 'PurchasesOrder',
      action: 'read',
    },
  },
  {
    path: '/purchase/order/add',
    name: 'purchase-addPurchasesOrder',
    component: () => import('@/views/purchase/order/add.vue'),
    meta: {
      pageTitle: 'Add Purchases Order',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Add Purchases Order',
          active: true,
        },
      ],
      resource: 'PurchasesOrder',
      action: 'add',
    },
  },
  {
    path: '/purchase/order/update/:id',
    name: 'purchase-updatePurchasesOrder',
    component: () => import('@/views/purchase/order/update.vue'),
    meta: {
      pageTitle: 'Update Purchases Order',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Update Purchases Order',
          active: true,
        },
      ],
      resource: 'PurchasesOrder',
      action: 'update',
    },
  },

  {
    path: '/purchase/invoice/index',
    name: 'purchase-invoice',
    component: () => import('@/views/purchase/invoice/index.vue'),
    meta: {
      pageTitle: 'Purchases Invoice',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Invoice',
          active: true,
        },
      ],
      resource: 'PurchasesInvoice',
      action: 'read',
    },
  },
  {
    path: '/purchase/invoice/preview/:id',
    name: 'purchase-previewInvoice',
    component: () => import('@/views/purchase/invoice/preview.vue'),
    meta: {
      resource: 'PurchasesInvoice',
      action: 'read',
    },
  },
  {
    path: '/purchase/invoice/add',
    name: 'purchase-addPurchasesInvoice',
    component: () => import('@/views/purchase/invoice/add.vue'),
    meta: {
      pageTitle: 'Add Purchases Invoice',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Add Purchases Invoice',
          active: true,
        },
      ],
      resource: 'PurchasesInvoice',
      action: 'add',
    },
  },
  {
    path: '/purchase/invoice/update/:id',
    name: 'purchase-updatePurchasesInvoice',
    component: () => import('@/views/purchase/invoice/update.vue'),
    meta: {
      pageTitle: 'Update Purchases Invoice',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Update Purchases Invoice',
          active: true,
        },
      ],
      resource: 'PurchasesInvoice',
      action: 'update',
    },
  },

  {
    path: '/purchase/return/index',
    name: 'purchase-return',
    component: () => import('@/views/purchase/return/index.vue'),
    meta: {
      pageTitle: 'Purchases Return',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Return',
          active: true,
        },
      ],
      resource: 'PurchasesReturn',
      action: 'read',
    },
  },
  {
    path: '/purchase/return/preview/:id',
    name: 'purchase-previewReturn',
    component: () => import('@/views/purchase/return/preview.vue'),
    meta: {
      resource: 'PurchasesReturn',
      action: 'read',
    },
  },
  {
    path: '/purchase/return/add',
    name: 'purchase-addPurchasesReturn',
    component: () => import('@/views/purchase/return/add.vue'),
    meta: {
      pageTitle: 'Add Purchases Return',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Add Purchases Return',
          active: true,
        },
      ],
      resource: 'PurchasesReturn',
      action: 'add',
    },
  },
  {
    path: '/purchase/return/update/:id',
    name: 'purchase-updatePurchasesReturn',
    component: () => import('@/views/purchase/return/update.vue'),
    meta: {
      pageTitle: 'Update Purchases Return',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Update Purchases Return',
          active: true,
        },
      ],
      resource: 'PurchasesReturn',
      action: 'update',
    },
  },

  {
    path: '/purchase/report/invoice',
    name: 'purchase-report-invoice',
    component: () => import('@/views/purchase/report/invoiceReport.vue'),
    meta: {
      pageTitle: 'Purchases Invoice Report',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Invoice Report',
          active: true,
        },
      ],
      resource: 'PurchasesInvoice',
      action: 'read',
    },
  },
  {
    path: '/purchase/report/order',
    name: 'purchase-report-order',
    component: () => import('@/views/purchase/report/orderReport.vue'),
    meta: {
      pageTitle: 'Purchases Order Report',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Order Report',
          active: true,
        },
      ],
      resource: 'PurchasesOrder',
      action: 'read',
    },
  },
  {
    path: '/purchase/report/offer',
    name: 'purchase-report-offer',
    component: () => import('@/views/purchase/report/offerReport.vue'),
    meta: {
      pageTitle: 'Purchases Price Offer Report',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Price Offer Report',
          active: true,
        },
      ],
      resource: 'PurchasesPriceOffer',
      action: 'read',
    },
  },
  {
    path: '/purchase/report/request',
    name: 'purchase-report-request',
    component: () => import('@/views/purchase/report/requestReport.vue'),
    meta: {
      pageTitle: 'Purchases Request Report',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Request Report',
          active: true,
        },
      ],
      resource: 'PurchasesRequest',
      action: 'read',
    },
  },
  {
    path: '/purchase/report/return',
    name: 'purchase-report-return',
    component: () => import('@/views/purchase/report/returnReport.vue'),
    meta: {
      pageTitle: 'Purchases Return Report',
      breadcrumb: [
        {
          text: 'Purchases',
        },
        {
          text: 'Purchases Return Report',
          active: true,
        },
      ],
      resource: 'PurchasesReturn',
      action: 'read',
    },
  },
]
