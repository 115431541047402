export default [
  {
    path: '/warehouse/item/index',
    name: 'warehouse-items',
    component: () => import('@/views/warehouse/item/index.vue'),
    meta: {
      pageTitle: 'Items',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Items',
          active: true,
        },
      ],
      resource: 'Item',
      action: 'read',
    },
  },
  {
    path: '/warehouse/item/add',
    name: 'warehouse-addItem',
    component: () => import('@/views/warehouse/item/add.vue'),
    meta: {
      pageTitle: 'Add Item',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Item',
          active: true,
        },
      ],
      resource: 'Item',
      action: 'add',
    },
  },
  {
    path: '/warehouse/item/update/:id',
    name: 'warehouse-updateItem',
    component: () => import('@/views/warehouse/item/update.vue'),
    meta: {
      pageTitle: 'Update Item',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Item',
          active: true,
        },
      ],
      resource: 'Item',
      action: 'update',
    },
  },
  {
    path: '/warehouse/itemGroup/index',
    name: 'warehouse-itemGroups',
    component: () => import('@/views/warehouse/item-group/index.vue'),
    meta: {
      pageTitle: 'Items Groups',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Items Groups',
          active: true,
        },
      ],
      resource: 'ItemGroup',
      action: 'read',
    },
  },
  {
    path: '/warehouse/itemGroup/add',
    name: 'warehouse-addItemGroup',
    component: () => import('@/views/warehouse/item-group/add.vue'),
    meta: {
      pageTitle: 'Add Item Group',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Item Group',
          active: true,
        },
      ],
      resource: 'ItemGroup',
      action: 'add',
    },
  },
  {
    path: '/warehouse/itemGroup/update/:id',
    name: 'warehouse-updateItemGroup',
    component: () => import('@/views/warehouse/item-group/update.vue'),
    meta: {
      pageTitle: 'Update Item Group',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Item Group',
          active: true,
        },
      ],
      resource: 'ItemGroup',
      action: 'update',
    },
  },
  {
    path: '/warehouse/itemType/index',
    name: 'warehouse-itemTypes',
    component: () => import('@/views/warehouse/item-type/index.vue'),
    meta: {
      pageTitle: 'Item Types',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'item Types',
          active: true,
        },
      ],
      resource: 'ItemType',
      action: 'read',
    },
  },
  {
    path: '/warehouse/itemType/add',
    name: 'warehouse-addItemType',
    component: () => import('@/views/warehouse/item-type/add.vue'),
    meta: {
      pageTitle: 'Add Item Type',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Item Type',
          active: true,
        },
      ],
      resource: 'ItemType',
      action: 'add',
    },
  },
  {
    path: '/warehouse/itemType/update/:id',
    name: 'warehouse-updateItemType',
    component: () => import('@/views/warehouse/item-type/update.vue'),
    meta: {
      pageTitle: 'Update Item Type',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Item Type',
          active: true,
        },
      ],
      resource: 'ItemType',
      action: 'update',
    },
  },
  {
    path: '/warehouse/itemStructure/index',
    name: 'warehouse-itemStructures',
    component: () => import('@/views/warehouse/item-structure/index.vue'),
    meta: {
      pageTitle: 'Item Structures',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Item Structures',
          active: true,
        },
      ],
      resource: 'ItemStructure',
      action: 'read',
    },
  },
  {
    path: '/warehouse/itemStructure/add',
    name: 'warehouse-addItemStructure',
    component: () => import('@/views/warehouse/item-structure/add.vue'),
    meta: {
      pageTitle: 'Add Item Structure',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Item Structure',
          active: true,
        },
      ],
      resource: 'ItemStructure',
      action: 'add',
    },
  },
  {
    path: '/warehouse/itemStructure/update/:id',
    name: 'warehouse-updateItemStructure',
    component: () => import('@/views/warehouse/item-structure/update.vue'),
    meta: {
      pageTitle: 'Update Item Structure',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Item Structure',
          active: true,
        },
      ],
      resource: 'ItemStructure',
      action: 'update',
    },
  },
  {
    path: '/warehouse/brand/index',
    name: 'warehouse-brands',
    component: () => import('@/views/warehouse/brand/index.vue'),
    meta: {
      pageTitle: 'Brands',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Brands',
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'read',
    },
  },
  {
    path: '/warehouse/brand/add',
    name: 'warehouse-addBrand',
    component: () => import('@/views/warehouse/brand/add.vue'),
    meta: {
      pageTitle: 'Add Brand',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Brand',
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'add',
    },
  },
  {
    path: '/warehouse/brand/update/:id',
    name: 'warehouse-updateBrand',
    component: () => import('@/views/warehouse/brand/update.vue'),
    meta: {
      pageTitle: 'Update Brand',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Brand',
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'update',
    },
  },
  {
    path: '/warehouse/logicted/index',
    name: 'warehouse-logicteds',
    component: () => import('@/views/warehouse/logicted/index.vue'),
    meta: {
      pageTitle: 'Logicteds',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Logicteds',
          active: true,
        },
      ],
      resource: 'Logicted',
      action: 'read',
    },
  },
  {
    path: '/warehouse/logicted/add',
    name: 'warehouse-addLogicted',
    component: () => import('@/views/warehouse/logicted/add.vue'),
    meta: {
      pageTitle: 'Add Logicted',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Logicted',
          active: true,
        },
      ],
      resource: 'Logicted',
      action: 'add',
    },
  },
  {
    path: '/warehouse/logicted/update/:id',
    name: 'warehouse-updateLogicted',
    component: () => import('@/views/warehouse/logicted/update.vue'),
    meta: {
      pageTitle: 'Update Logicted',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Logicted',
          active: true,
        },
      ],
      resource: 'Logicted',
      action: 'update',
    },
  },
  {
    path: '/warehouse/measurementUnit/index',
    name: 'warehouse-measurementUnits',
    component: () => import('@/views/warehouse/measurement-unit/index.vue'),
    meta: {
      pageTitle: 'Measurement Unit',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Measurement Unit',
          active: true,
        },
      ],
      resource: 'MeasurementUnit',
      action: 'read',
    },
  },
  {
    path: '/warehouse/measurementUnit/add',
    name: 'warehouse-addMeasurementUnit',
    component: () => import('@/views/warehouse/measurement-unit/add.vue'),
    meta: {
      pageTitle: 'Add Measurement Unit',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Measurement Unit',
          active: true,
        },
      ],
      resource: 'MeasurementUnit',
      action: 'add',
    },
  },
  {
    path: '/warehouse/measurementUnit/update/:id',
    name: 'warehouse-updateMeasurementUnit',
    component: () => import('@/views/warehouse/measurement-unit/update.vue'),
    meta: {
      pageTitle: 'Update Measurement Unit',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Measurement Unit',
          active: true,
        },
      ],
      resource: 'MeasurementUnit',
      action: 'update',
    },
  },
  {
    path: '/warehouse/warehouse/index',
    name: 'warehouse-warehouses',
    component: () => import('@/views/warehouse/warehouse/index.vue'),
    meta: {
      pageTitle: 'Warehouses',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Warehouses',
          active: true,
        },
      ],
      resource: 'Warehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/warehouse/add',
    name: 'warehouse-addWarehouse',
    component: () => import('@/views/warehouse/warehouse/add.vue'),
    meta: {
      pageTitle: 'Add Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Warehouse',
          active: true,
        },
      ],
      resource: 'Warehouse',
      action: 'add',
    },
  },
  {
    path: '/warehouse/warehouse/update/:id',
    name: 'warehouse-updateWarehouse',
    component: () => import('@/views/warehouse/warehouse/update.vue'),
    meta: {
      pageTitle: 'Update Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Warehouse',
          active: true,
        },
      ],
      resource: 'Warehouse',
      action: 'update',
    },
  },
  {
    path: '/warehouse/warehousePart/index',
    name: 'warehouse-warehouseParts',
    component: () => import('@/views/warehouse/warehouse-part/index.vue'),
    meta: {
      pageTitle: 'Warehouse Parts',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Warehouse Parts',
          active: true,
        },
      ],
      resource: 'WarehousePart',
      action: 'read',
    },
  },
  {
    path: '/warehouse/warehousePart/add',
    name: 'warehouse-addWarehousePart',
    component: () => import('@/views/warehouse/warehouse-part/add.vue'),
    meta: {
      pageTitle: 'Add Warehouse Part',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Warehouse Part',
          active: true,
        },
      ],
      resource: 'WarehousePart',
      action: 'add',
    },
  },
  {
    path: '/warehouse/warehousePart/update/:id',
    name: 'warehouse-updateWarehousePart',
    component: () => import('@/views/warehouse/warehouse-part/update.vue'),
    meta: {
      pageTitle: 'Update Warehouse Part',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Warehouse Part',
          active: true,
        },
      ],
      resource: 'WarehousePart',
      action: 'update',
    },
  },
  {
    path: '/warehouse/deposit/index',
    name: 'warehouse-deposit',
    component: () => import('@/views/warehouse/deposit/index.vue'),
    meta: {
      pageTitle: 'Deposit Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Deposit Warehouse',
          active: true,
        },
      ],
      resource: 'DepositWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/deposit/preview/:id',
    name: 'warehouse-previewDeposit',
    component: () => import('@/views/warehouse/deposit/preview.vue'),
    meta: {
      resource: 'DepositWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/deposit/add',
    name: 'warehouse-addDeposit',
    component: () => import('@/views/warehouse/deposit/add.vue'),
    meta: {
      pageTitle: 'Deposit Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Deposit Warehouse',
          active: true,
        },
      ],
      resource: 'DepositWarehouse',
      action: 'add',
    },
  },
  {
    path: '/warehouse/deposit/update/:id',
    name: 'warehouse-updateDeposit',
    component: () => import('@/views/warehouse/deposit/update.vue'),
    meta: {
      pageTitle: 'Deposit Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Deposit Warehouse',
          active: true,
        },
      ],
      resource: 'DepositWarehouse',
      action: 'update',
    },
  },
  {
    path: '/warehouse/withdraw/index',
    name: 'warehouse-withdraw',
    component: () => import('@/views/warehouse/withdraw/index.vue'),
    meta: {
      pageTitle: 'Withdraw Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Withdraw Warehouse',
          active: true,
        },
      ],
      resource: 'WithdrawWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/withdraw/preview/:id',
    name: 'warehouse-previewWithdraw',
    component: () => import('@/views/warehouse/withdraw/preview.vue'),
    meta: {
      resource: 'WithdrawWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/withdraw/add',
    name: 'warehouse-addWithdraw',
    component: () => import('@/views/warehouse/withdraw/add.vue'),
    meta: {
      pageTitle: 'Withdraw Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Withdraw Warehouse',
          active: true,
        },
      ],
      resource: 'WithdrawWarehouse',
      action: 'add',
    },
  },
  {
    path: '/warehouse/withdraw/update/:id',
    name: 'warehouse-updateWithdraw',
    component: () => import('@/views/warehouse/withdraw/update.vue'),
    meta: {
      pageTitle: 'Update Withdraw Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Withdraw Warehouse',
          active: true,
        },
      ],
      resource: 'WithdrawWarehouse',
      action: 'update',
    },
  },
  {
    path: '/warehouse/transferWarehouse/index',
    name: 'warehouse-transferWarehouses',
    component: () => import('@/views/warehouse/transfer-warehouse/index.vue'),
    meta: {
      pageTitle: 'Transfer Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Transfer Warehouse',
          active: true,
        },
      ],
      resource: 'TransferWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/transfer/preview/:id',
    name: 'warehouse-previewTransfer',
    component: () => import('@/views/warehouse/transfer-warehouse/preview.vue'),
    meta: {
      resource: 'TransferWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/transferWarehouse/add',
    name: 'warehouse-addTransferWarehouse',
    component: () => import('@/views/warehouse/transfer-warehouse/add.vue'),
    meta: {
      pageTitle: 'Add Transfer Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Add Transfer Warehouse',
          active: true,
        },
      ],
      resource: 'TransferWarehouse',
      action: 'add',
    },
  },
  {
    path: '/warehouse/TransferWarehouse/update/:id',
    name: 'warehouse-updateTransferWarehouse',
    component: () => import('@/views/warehouse/transfer-warehouse/update.vue'),
    meta: {
      pageTitle: 'Update Transfer Warehouse',
      breadcrumb: [
        {
          text: 'warehouse',
        },
        {
          text: 'Update Transfer Warehouse',
          active: true,
        },
      ],
      resource: 'TransferWarehouse',
      action: 'update',
    },
  },
  {
    path: '/warehouse/destroy/index',
    name: 'warehouse-destroy',
    component: () => import('@/views/warehouse/destroy/index.vue'),
    meta: {
      pageTitle: 'Destroy Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Destroy Warehouse',
          active: true,
        },
      ],
      resource: 'DestroyWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/destroy/preview/:id',
    name: 'warehouse-previewDestroy',
    component: () => import('@/views/warehouse/destroy/preview.vue'),
    meta: {
      resource: 'DestroyWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/destroy/add',
    name: 'warehouse-addDestroy',
    component: () => import('@/views/warehouse/destroy/add.vue'),
    meta: {
      pageTitle: 'Destroy Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Destroy Warehouse',
          active: true,
        },
      ],
      resource: 'DestroyWarehouse',
      action: 'add',
    },
  },
  {
    path: '/warehouse/destroy/update/:id',
    name: 'warehouse-updateDestroy',
    component: () => import('@/views/warehouse/destroy/update.vue'),
    meta: {
      pageTitle: 'Update Destroy Warehouse',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Update Destroy Warehouse',
          active: true,
        },
      ],
      resource: 'DestroyWarehouse',
      action: 'update',
    },
  },

  {
    path: '/warehouse/report/deposit',
    name: 'warehouse-report-deposit',
    component: () => import('@/views/warehouse/report/depositReport.vue'),
    meta: {
      pageTitle: 'Deposit Warehouse Report',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Deposit Warehouse Report',
          active: true,
        },
      ],
      resource: 'DepositWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/report/withdraw',
    name: 'warehouse-report-withdraw',
    component: () => import('@/views/warehouse/report/withdrawReport.vue'),
    meta: {
      pageTitle: 'Withdraw Warehouse Report',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Withdraw Warehouse Report',
          active: true,
        },
      ],
      resource: 'WithdrawWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/report/transferWarehouses',
    name: 'warehouse-report-transferWarehouses',
    component: () => import('@/views/warehouse/report/transferReport.vue'),
    meta: {
      pageTitle: 'Transfer Warehouse Report',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Transfer Warehouse Report',
          active: true,
        },
      ],
      resource: 'TransferWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/report/destroy',
    name: 'warehouse-report-destroy',
    component: () => import('@/views/warehouse/report/destroyReport.vue'),
    meta: {
      pageTitle: 'Destroy Warehouse Report',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Destroy Warehouse Report',
          active: true,
        },
      ],
      resource: 'DestroyWarehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/report/status',
    name: 'warehouse-report-status',
    component: () => import('@/views/warehouse/report/statusReport.vue'),
    meta: {
      pageTitle: 'Status Warehouse Report',
      breadcrumb: [
        {
          text: 'Warehouse',
        },
        {
          text: 'Status Warehouse Report',
          active: true,
        },
      ],
      resource: 'WarehouseHeader',
      action: 'read',
    },
  },
]
