export default [
  {
    path: '/crm/supplier/index',
    name: 'crm-suppliers',
    component: () => import('@/views/crm/supplier/index.vue'),
    meta: {
      pageTitle: 'Suppliers',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Suppliers',
          active: true,
        },
      ],
      resource: 'Supplier',
      action: 'read',
    },
  },
  {
    path: '/crm/supplier/add',
    name: 'crm-addSupplier',
    component: () => import('@/views/crm/supplier/add.vue'),
    meta: {
      pageTitle: 'Add Supplier',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Add Supplier',
          active: true,
        },
      ],
      resource: 'Supplier',
      action: 'add',
    },
  },
  {
    path: '/crm/supplier/update/:id',
    name: 'crm-updateSupplier',
    component: () => import('@/views/crm/supplier/update.vue'),
    meta: {
      pageTitle: 'Update Supplier',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Update Supplier',
          active: true,
        },
      ],
      resource: 'Supplier',
      action: 'update',
    },
  },
  {
    path: '/crm/client/index',
    name: 'crm-clients',
    component: () => import('@/views/crm/client/index.vue'),
    meta: {
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Clients',
          active: true,
        },
      ],
      resource: 'Client',
      action: 'read',
    },
  },
  {
    path: '/crm/client/add',
    name: 'crm-addClient',
    component: () => import('@/views/crm/client/add.vue'),
    meta: {
      pageTitle: 'Add Client',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Add Client',
          active: true,
        },
      ],
      resource: 'Client',
      action: 'add',
    },
  },
  {
    path: '/crm/client/update/:id',
    name: 'crm-updateClient',
    component: () => import('@/views/crm/client/update.vue'),
    meta: {
      pageTitle: 'Update Client',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Update Client',
          active: true,
        },
      ],
      resource: 'Client',
      action: 'update',
    },
  },
  {
    path: '/crm/type/index',
    name: 'crm-types',
    component: () => import('@/views/crm/type/index.vue'),
    meta: {
      pageTitle: 'Types',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Types',
          active: true,
        },
      ],
      resource: 'TypeSupplier',
      action: 'read',
    },
  },
  {
    path: '/crm/type/add',
    name: 'crm-addType',
    component: () => import('@/views/crm/type/add.vue'),
    meta: {
      pageTitle: 'Add Type',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Add Type',
          active: true,
        },
      ],
      resource: 'TypeSupplier',
      action: 'add',
    },
  },
  {
    path: '/crm/type/update/:id',
    name: 'crm-updateType',
    component: () => import('@/views/crm/type/update.vue'),
    meta: {
      pageTitle: 'Update Type',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Update Type',
          active: true,
        },
      ],
      resource: 'TypeSupplier',
      action: 'update',
    },
  },
  {
    path: '/crm/company/index',
    name: 'crm-companies',
    component: () => import('@/views/crm/company/index.vue'),
    meta: {
      pageTitle: 'Companies',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Companies',
          active: true,
        },
      ],
      resource: 'Company',
      action: 'read',
    },
  },
  {
    path: '/crm/company/add',
    name: 'crm-addCompany',
    component: () => import('@/views/crm/company/add.vue'),
    meta: {
      pageTitle: 'Add Company',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Add Company',
          active: true,
        },
      ],
      resource: 'Company',
      action: 'add',
    },
  },
  {
    path: '/crm/company/update/:id',
    name: 'crm-updateCompany',
    component: () => import('@/views/crm/company/update.vue'),
    meta: {
      pageTitle: 'Update Company',
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Update Company',
          active: true,
        },
      ],
      resource: 'Company',
      action: 'update',
    },
  },
]
