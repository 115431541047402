export default [
  {
    path: '/sale/request/index',
    name: 'sale-request',
    component: () => import('@/views/sale/request/index.vue'),
    meta: {
      pageTitle: 'Sales Request',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Request',
          active: true,
        },
      ],
      resource: 'SalesRequest',
      action: 'read',
    },
  },
  {
    path: '/sale/request/preview/:id',
    name: 'sale-previewRequest',
    component: () => import('@/views/sale/request/preview.vue'),
    meta: {
      resource: 'SalesRequest',
      action: 'read',
    },
  },
  {
    path: '/sale/request/add',
    name: 'sale-addSalesRequest',
    component: () => import('@/views/sale/request/add.vue'),
    meta: {
      pageTitle: 'Add Sales Request',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Add Sales Request',
          active: true,
        },
      ],
      resource: 'SalesRequest',
      action: 'add',
    },
  },
  {
    path: '/sale/request/update/:id',
    name: 'sale-updateSalesRequest',
    component: () => import('@/views/sale/request/update.vue'),
    meta: {
      pageTitle: 'Update Sales Request',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Update Sales Request',
          active: true,
        },
      ],
      resource: 'SalesRequest',
      action: 'update',
    },
  },

  {
    path: '/sale/offer/index',
    name: 'sale-offer',
    component: () => import('@/views/sale/offer/index.vue'),
    meta: {
      pageTitle: 'Sales Price Offer',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Price Offer',
          active: true,
        },
      ],
      resource: 'SalesPriceOffer',
      action: 'read',
    },
  },
  {
    path: '/sale/offer/preview/:id',
    name: 'sale-previewOffer',
    component: () => import('@/views/sale/offer/preview.vue'),
    meta: {
      resource: 'SalesPriceOffer',
      action: 'read',
    },
  },
  {
    path: '/sale/offer/add',
    name: 'sale-addPriceOffer',
    component: () => import('@/views/sale/offer/add.vue'),
    meta: {
      pageTitle: 'Add Sales Price Offer',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Add Price Offer',
          active: true,
        },
      ],
      resource: 'SalesPriceOffer',
      action: 'add',
    },
  },
  {
    path: '/sale/offer/update/:id',
    name: 'sale-updatePriceOffer',
    component: () => import('@/views/sale/offer/update.vue'),
    meta: {
      pageTitle: 'Update Sales Price Offer',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Update Price Offer',
          active: true,
        },
      ],
      resource: 'SalesPriceOffer',
      action: 'update',
    },
  },

  {
    path: '/sale/order/index',
    name: 'sale-order',
    component: () => import('@/views/sale/order/index.vue'),
    meta: {
      pageTitle: 'Order',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Order',
          active: true,
        },
      ],
      resource: 'SalesOrder',
      action: 'read',
    },
  },
  {
    path: '/sale/order/preview/:id',
    name: 'sale-previewOrder',
    component: () => import('@/views/sale/order/preview.vue'),
    meta: {
      resource: 'SalesOrder',
      action: 'read',
    },
  },
  {
    path: '/sale/order/add',
    name: 'sale-addSalesOrder',
    component: () => import('@/views/sale/order/add.vue'),
    meta: {
      pageTitle: 'Add Sales Order',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Add Sales Order',
          active: true,
        },
      ],
      resource: 'SalesOrder',
      action: 'add',
    },
  },
  {
    path: '/sale/order/update/:id',
    name: 'sale-updateSalesOrder',
    component: () => import('@/views/sale/order/update.vue'),
    meta: {
      pageTitle: 'Update Sales Order',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Update Sales Order',
          active: true,
        },
      ],
      resource: 'SalesOrder',
      action: 'update',
    },
  },

  {
    path: '/sale/invoice/index',
    name: 'sale-invoice',
    component: () => import('@/views/sale/invoice/index.vue'),
    meta: {
      pageTitle: 'Sales Invoice',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/invoice/preview/:id',
    name: 'sale-previewInvoice',
    component: () => import('@/views/sale/invoice/preview.vue'),
    meta: {
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/invoice/add',
    name: 'sale-addSalesInvoice',
    component: () => import('@/views/sale/invoice/add.vue'),
    meta: {
      pageTitle: 'Add Sales Invoice',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Add Sales Invoice',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'add',
    },
  },
  {
    path: '/sale/invoice/update/:id',
    name: 'sale-updateSalesInvoice',
    component: () => import('@/views/sale/invoice/update.vue'),
    meta: {
      pageTitle: 'Update Sales Invoice',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Update Sales Invoice',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'update',
    },
  },

  {
    path: '/sale/return/index',
    name: 'sale-return',
    component: () => import('@/views/sale/return/index.vue'),
    meta: {
      pageTitle: 'Sales Return',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Return',
          active: true,
        },
      ],
      resource: 'SalesReturn',
      action: 'read',
    },
  },
  {
    path: '/sale/return/preview/:id',
    name: 'sale-previewReturn',
    component: () => import('@/views/sale/return/preview.vue'),
    meta: {
      resource: 'SalesReturn',
      action: 'read',
    },
  },
  {
    path: '/sale/return/add',
    name: 'sale-addSalesReturn',
    component: () => import('@/views/sale/return/add.vue'),
    meta: {
      pageTitle: 'Add Sales Return',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Add Sales Return',
          active: true,
        },
      ],
      resource: 'SalesReturn',
      action: 'add',
    },
  },
  {
    path: '/sale/return/update/:id',
    name: 'sale-updateSalesReturn',
    component: () => import('@/views/sale/return/update.vue'),
    meta: {
      pageTitle: 'Update Sales Return',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Update Sales Return',
          active: true,
        },
      ],
      resource: 'SalesReturn',
      action: 'update',
    },
  },
  // ------------------------------ Start Invoice Report ----------------------------------
  {
    path: '/sale/report/invoice/date',
    name: 'sales-report-invoiceByDate',
    component: () => import('@/views/sale/report/invoice/byDate.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Date',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Date',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/duedate',
    name: 'sales-report-invoiceByDueDate',
    component: () => import('@/views/sale/report/invoice/byDueDate.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Due Date',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Due Date',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/warehouse',
    name: 'sales-report-invoiceByWarehouse',
    component: () => import('@/views/sale/report/invoice/byWarehouse.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Warehouse',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Warehouse',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/branch',
    name: 'sales-report-invoiceByBranch',
    component: () => import('@/views/sale/report/invoice/byBranch.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Branch',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Branch',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/financiaylear',
    name: 'sales-report-invoiceByFinancialYear',
    component: () => import('@/views/sale/report/invoice/byFinancialYear.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Financial Year',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Financial Year',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/client',
    name: 'sales-report-invoiceByClient',
    component: () => import('@/views/sale/report/invoice/byVendorSet.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Client',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Client',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/baseddocument',
    name: 'sales-report-invoiceByBasedDocument',
    component: () => import('@/views/sale/report/invoice/byBasedDocument.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Based Document',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Based Document',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/status',
    name: 'sales-report-invoiceByStatus',
    component: () => import('@/views/sale/report/invoice/byStatus.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Status',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Status',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/item',
    name: 'sales-report-invoiceByItem',
    component: () => import('@/views/sale/report/invoice/byItem.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Item',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Item',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/invoice/user',
    name: 'sales-report-invoiceByUser',
    component: () => import('@/views/sale/report/invoice/byUser.vue'),
    meta: {
      pageTitle: 'Sales Invoice By Employee',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice By Employee',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  // ------------------------------ End Invoice Report ----------------------------------

  {
    path: '/sale/report/invoice',
    name: 'sale-report-invoice',
    component: () => import('@/views/sale/report/invoiceReport.vue'),
    meta: {
      pageTitle: 'Sales Invoice Report',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Invoice Report',
          active: true,
        },
      ],
      resource: 'SalesInvoice',
      action: 'read',
    },
  },
  {
    path: '/sale/report/order',
    name: 'sale-report-order',
    component: () => import('@/views/sale/report/orderReport.vue'),
    meta: {
      pageTitle: 'Sales Order Report',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Order Report',
          active: true,
        },
      ],
      resource: 'SalesOrder',
      action: 'read',
    },
  },
  {
    path: '/sale/report/offer',
    name: 'sale-report-offer',
    component: () => import('@/views/sale/report/offerReport.vue'),
    meta: {
      pageTitle: 'Sales Price Offer Report',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Price Offer Report',
          active: true,
        },
      ],
      resource: 'SalesPriceOffer',
      action: 'read',
    },
  },
  {
    path: '/sale/report/request',
    name: 'sale-report-request',
    component: () => import('@/views/sale/report/requestReport.vue'),
    meta: {
      pageTitle: 'Sales Request Report',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Request Report',
          active: true,
        },
      ],
      resource: 'SalesRequest',
      action: 'read',
    },
  },
  {
    path: '/sale/report/return',
    name: 'sale-report-return',
    component: () => import('@/views/sale/report/returnReport.vue'),
    meta: {
      pageTitle: 'Sales Return Report',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Return Report',
          active: true,
        },
      ],
      resource: 'SalesReturn',
      action: 'read',
    },
  },
]
