export default [
  {
    path: '/accounting/costCenter/index',
    name: 'accounting-costCenters',
    component: () => import('@/views/accounting/cost-center/index.vue'),
    meta: {
      pageTitle: 'Cost Centers',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Cost Centers',
          active: true,
        },
      ],
      resource: 'CostCenter',
      action: 'read',
    },
  },
  {
    path: '/accounting/costCenter/add',
    name: 'accounting-addCostCenter',
    component: () => import('@/views/accounting/cost-center/add.vue'),
    meta: {
      pageTitle: 'Add Cost Center',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Add Cost Center',
          active: true,
        },
      ],
      resource: 'CostCenter',
      action: 'add',
    },
  },
  {
    path: '/accounting/costCenter/update/:id',
    name: 'accounting-updateCostCenter',
    component: () => import('@/views/accounting/cost-center/update.vue'),
    meta: {
      pageTitle: 'Update Cost Center',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Update Cost Center',
          active: true,
        },
      ],
      resource: 'CostCenter',
      action: 'update',
    },
  },
  {
    path: '/accounting/financialYear/index',
    name: 'accounting-financialYears',
    component: () => import('@/views/accounting/financial-year/index.vue'),
    meta: {
      pageTitle: 'Financial Year',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Financial Year',
          active: true,
        },
      ],
      resource: 'FinancialYear',
      action: 'read',
    },
  },
  {
    path: '/accounting/financialYear/add',
    name: 'accounting-addFinancialYear',
    component: () => import('@/views/accounting/financial-year/add.vue'),
    meta: {
      pageTitle: 'Add Financial Year',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Add Financial Year',
          active: true,
        },
      ],
      resource: 'FinancialYear',
      action: 'add',
    },
  },
  {
    path: '/accounting/financialYear/update/:id',
    name: 'accounting-updateFinancialYear',
    component: () => import('@/views/accounting/financial-year/update.vue'),
    meta: {
      pageTitle: 'Update Financial Year',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Update Financial Year',
          active: true,
        },
      ],
      resource: 'FinancialYear',
      action: 'update',
    },
  },
  {
    path: '/accounting/accountTree/index',
    name: 'accounting-accountsTree',
    component: () => import('@/views/accounting/account-tree/index.vue'),
    meta: {
      pageTitle: 'Accounts Tree',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Account Tree',
          active: true,
        },
      ],
      resource: 'AccountTree',
      action: 'read',
    },
  },
  {
    path: '/accounting/accountTree/add',
    name: 'accounting-addAccountTree',
    component: () => import('@/views/accounting/account-tree/add.vue'),
    meta: {
      pageTitle: 'Add Account Tree',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Add Account Tree',
          active: true,
        },
      ],
      resource: 'AccountTree',
      action: 'add',
    },
  },
  {
    path: '/accounting/accountTree/update/:id',
    name: 'accounting-updateAccountTree',
    component: () => import('@/views/accounting/account-tree/update.vue'),
    meta: {
      pageTitle: 'Update Account Tree',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Update Account Tree',
          active: true,
        },
      ],
      resource: 'AccountTree',
      action: 'update',
    },
  },
  {
    path: '/accounting/restriction/index',
    name: 'accounting-restrictions',
    component: () => import('@/views/accounting/restriction/index.vue'),
    meta: {
      pageTitle: 'Restrictions',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Restrictions',
          active: true,
        },
      ],
      resource: 'Restriction',
      action: 'read',
    },
  },
  {
    path: '/accounting/restriction/add',
    name: 'accounting-addRestriction',
    component: () => import('@/views/accounting/restriction/add.vue'),
    meta: {
      pageTitle: 'Add Restriction',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Add Restriction',
          active: true,
        },
      ],
      resource: 'Restriction',
      action: 'add',
    },
  },
  {
    path: '/accounting/restriction/update/:id',
    name: 'accounting-updateRestriction',
    component: () => import('@/views/accounting/restriction/update.vue'),
    meta: {
      pageTitle: 'Update Restriction',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Update Restriction',
          active: true,
        },
      ],
      resource: 'Restriction',
      action: 'update',
    },
  },
  {
    path: '/accounting/report/statement',
    name: 'accounting-statements',
    component: () => import('@/views/accounting/report/statement.vue'),
    meta: {
      pageTitle: 'Accounting Statements',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Accounting Statements',
          active: true,
        },
      ],
      resource: 'AccountingStatement',
      action: 'read',
    },
  },
  {
    path: '/accounting/report/movement',
    name: 'accounting-movements',
    component: () => import('@/views/accounting/report/movement.vue'),
    meta: {
      pageTitle: 'Accounting Movements',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Accounting Movements',
          active: true,
        },
      ],
      resource: 'AccountingMovement',
      action: 'read',
    },
  },
  {
    path: '/accounting/report/trialBalance',
    name: 'accounting-trialBalance',
    component: () => import('@/views/accounting/report/trialBalance.vue'),
    meta: {
      pageTitle: 'Trial Balance',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Trial Balance',
          active: true,
        },
      ],
      resource: 'TrialBalance',
      action: 'read',
    },
  },
  {
    path: '/accounting/report/balanceSheet',
    name: 'accounting-balanceSheet',
    component: () => import('@/views/accounting/report/balanceSheet.vue'),
    meta: {
      pageTitle: 'Balance Sheet',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'Balance Sheet',
          active: true,
        },
      ],
      resource: 'BalanceSheet',
      action: 'read',
    },
  },
]
